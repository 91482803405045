
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { TweenMax } from "gsap";

@Component
export default class RasSlogan extends Vue {
  @Prop() private animDuration!: number;
  @Prop() private animDelay!: number;
  $refs!: {
    alphabetMask: HTMLElement;
    bullets: HTMLElement;
  };

  private interval: any;
  rasIndex: number = 0;
  RAS = [
    {
      alphabet: "R",
      eng: "Reliability",
      chinese: "可靠度"
    },
    {
      alphabet: "A",
      eng: "Availability",
      chinese: "可用度"
    },
    {
      alphabet: "S",
      eng: "Security",
      chinese: "安全性"
    }
  ];

  mounted() {
    const { alphabetMask } = this.$refs;
    TweenMax.to(alphabetMask, this.animDuration, {
      width: "100%",
      delay: this.animDelay * 5,
      onComplete: () => {
        this.interval = setInterval(() => {
          this.rasAnim();
        }, 2200);
      }
    });
  }

  destroyed() {
    clearInterval(this.interval);
  }

  private rasAnim() {
    const { bullets } = this.$refs;
    const nextIndex = (this.rasIndex + 1) % this.RAS.length;
    TweenMax.to(bullets, 0.3, {
      y: -nextIndex * 50
    });
    this.rasIndex = nextIndex;
  }
}
