
import { Component, Vue } from "vue-property-decorator";
import { TweenMax, Expo } from "gsap";
import RasSlogan from "./components/RasSlogan.vue";

@Component({
  components: {
    RasSlogan
  }
})
export default class Landing extends Vue {
  animDuration: number = 0.5;
  animDelay: number = 0.2;
  $refs!: {
    bgBlock: HTMLElement;
    studioSlogan: HTMLElement;
  };

  mounted() {
    this.init();
  }

  init() {
    const { bgBlock, studioSlogan } = this.$refs;
    TweenMax.to(bgBlock, this.animDuration, {
      flexBasis: `100%`,
      delay: this.animDelay,
      ease: Expo.easeOut
    });
    TweenMax.to(studioSlogan, this.animDuration, {
      color: "#3b3b3b",
      delay: this.animDelay * 2
    });
  }
}
