import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faGithub,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhoneAlt,
  faAngleRight,
  faAngleLeft,
  faDollarSign,
  faStopwatch,
  faComment,
  faDesktop,
  faGlobe
} from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGtag from "vue-gtag";
import i18n from "./i18n";

Vue.use(
  VueGtag,
  {
    config: { id: "UA-165340272-1" },
    appName: "RAS",
    pageTrackerScreenviewEnabled: true
  },
  router
);

library.add(
  faFacebookF,
  faGithub,
  faInstagram,
  faEnvelope,
  faPhoneAlt,
  faAngleRight,
  faAngleLeft,
  faDollarSign,
  faStopwatch,
  faComment,
  faDesktop,
  faSmile,
  faGlobe
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.directive("focus", {
  inserted: function(el, binding) {
    if (binding.value) {
      el.focus();
    }
  }
});

router.beforeEach((to, from, next) => {
  const metaAttribute = "data-mata";
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll(`[${metaAttribute}]`)).map((el: any) =>
    el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: any) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key: any) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute(`${metaAttribute}`, "");

      return tag;
    })
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");
