
import { Component, Vue } from "vue-property-decorator";
import { Lang } from "@/config/langs";
import { setUserLang } from "@/i18n";
@Component
export default class LanguageOptions extends Vue {
  languageOptions = [
    {
      title: "中文",
      value: Lang.TW
    },
    {
      title: "EN",
      value: Lang.EN
    }
    // {
    //   title: "日本語",
    //   value: Lang.JA
    // }
  ];
  currentLocale: string = "";

  mounted(): void {
    this.currentLocale = this.$i18n.locale;
  }

  onSelect(value: Lang): void {
    setUserLang(value);
    window.location.reload();
  }
}
