
import { Component, Vue } from "vue-property-decorator";
import ServiceCard from "./components/ServiceCard.vue";

@Component({
  components: {
    ServiceCard
  }
})
export default class Services extends Vue {
  services = [
    // content should be ~ 40 Chinese characters
    {
      hashtags: "#公司網站 #品牌形象 #活動網頁",
      title: "單面網站設計",
      price: "9,000",
      subtitle: "Static site",
      details: ["UI / UX 設計", "RWD 響應式設計", "簡單內容編輯後台"]
    },
    {
      hashtags: "#動態網站 #訂購功能",
      title: "購物車網站",
      price: "30,000",
      subtitle: "Shopping site",
      details: ["購物車系統", "主機伺服器管理", "物流金流串接"]
    },
    {
      hashtags: "#iOS #Android #智慧型裝置 #app",
      title: "智慧手機程式",
      price: "45,000",
      subtitle: "Mobile application",
      details: ["手機應用程式", "平板應用程式", "雙平台支援"]
    },
    {
      hashtags: "#bot #聊天機器人 #Line #Facebook",
      title: "自動化聊天機器人",
      price: "15,000",
      subtitle: "Chat bot",
      details: ["行銷訊息推送", "會員互動式服務", "跨平台聊天機器人"]
    },
    {
      hashtags: "#內部系統 #管理系統",
      title: "自定義網站",
      price: "10,000",
      subtitle: "Dynamic site",
      details: [
        "討論需求後開價格",
        "資料庫設計與分析",
        "客製化後台監控/更改網站"
      ]
    }
  ];
}
