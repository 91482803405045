import en from "./en";
import tw from "./zhTW";
import ja from "./ja";

export enum Lang {
  EN = "en",
  TW = "zh-TW",
  JA = "ja"
}

const messages = {
  [Lang.EN]: en,
  [Lang.TW]: tw,
  [Lang.JA]: ja
};

export default messages;
