
import { Component, Vue } from "vue-property-decorator";

@Component
export default class WhyUs extends Vue {
  why_us_cards = [
    // content should be ~ 40 Chinese characters
    {
      big_icon: "dollar-sign",
      title: this.$t("whyUs.1.title"),
      aspect: this.$t("whyUs.1.aspect"),
      content: this.$t("whyUs.1.content")
    },
    {
      big_icon: "stopwatch",
      title: this.$t("whyUs.2.title"),
      aspect: this.$t("whyUs.2.aspect"),
      content: this.$t("whyUs.2.content")
    },
    {
      big_icon: "comment",
      title: this.$t("whyUs.3.title"),
      aspect: this.$t("whyUs.3.aspect"),
      content: this.$t("whyUs.3.content")
    },
    {
      big_icon: "desktop",
      title: this.$t("whyUs.4.title"),
      aspect: this.$t("whyUs.4.aspect"),
      content: this.$t("whyUs.4.content")
    }
  ];
}
