export default {
  slogan: {
    title: "Delivering the most reliable, usable and secure technology."
  },
  whyUs: {
    title: "Choose RAS",
    1: {
      title: "Open and Transparent",
      aspect: "Pricing",
      content:
        "During the RAS estimation process, we analyze the requirements and detailed specifications of the components you need."
    },
    2: {
      title: "Clearly and Understandable",
      aspect: "Schedule",
      content:
        "Through the effective use of project planning management tools, we can estimate the time for the four major milestones of design, development, testing, and handover, and deliver on time."
    },
    3: {
      title: "Timely and Effective",
      aspect: "Communication",
      content:
        "We use a single contact window to make communication more direct and easy. On business days, you'll be sure to hear back from RAS within 24 hours."
    },
    4: {
      title: "Customization",
      aspect: "Development",
      content:
        "Create your own customized image website through RAS. During the consultation and discussion process, RAS will be able to better understand your needs and answer your questions."
    }
  },
  link: {
    home: "Home",
    blog: "Blog",
    portfolio: "Portfolio",
    about: "About us",
    faq: "FAQ"
  },
  portfolio: {
    notActive: "Not active any more",
    lebecca: "Lebecca Biotechnology - Shopping Website",
    molong: "Pojiali - Shopping Website",
    molongLineBot: "Pojiali LINE Bot",
    musicAILab: "Music and Artificial Intelligence Lab",
    ntuElection: "NTU Election Polls"
  },
  contact: {
    title: "Contact us",
    subTitle:
      "If you are willing to cooperate, please feel free to contact us, we will reply as soon as possible after receiving your message.",
    messageStep: {
      1: {
        title: "Your email",
        subTitle:
          "Please double-check that you have filled out the form correctly.",
        placeHolder: "Fill in your email"
      },
      2: {
        title: "Subject",
        subTitle:
          "Do me a favor and type in a simple subject line! So that we can see it at a glance.",
        placeHolder: "Fill in the subject"
      },
      3: {
        title: "Content",
        subTitle:
          "Tell us about your needs and expectations so that we can plan ahead before we get back to you.",
        placeHolder: "Fill in the content"
      },
      4: {
        title: "Thank you for taking the first step :)",
        subTitle: "Nice to meet you, we will be in touch soon!"
      }
    },
    error: {
      email: "Please check if the email is correct!",
      subject: "Please enter a subject!",
      subjectLimit: "Please keep the subject to 30 words!",
      content: "Please enter content!",
      contentLimit: "Please enter 300 words or less for the content!"
    }
  },
  about: {
    1: "RAS, in addition to standing for Reliability, Availability and Security, the letters RAS are also derived from the beginnings of the studio's three names, Ryan, Andrew and Steven.",
    2: "We are graduates of National Taiwan University and have been working together on projects large and small since we were students, such as NTU's student presidential election website and Academia Sinica's Music AI Lab website.",
    3: "Why do we have this studio? We were sitting in a cafe one afternoon, chatting, remembering the sweet and sour of working together as students, and then thinking why not use our free time to work on projects together again; the RAS studio is currently focused on taking on projects and slowly developing projects that we find interesting. Last but not least, we have a common understanding that we do things the best way we can, and as RAS stands for, we provide reliable, usable, and secure services, and we are committed to creating an interface that is both usable and aesthetically pleasing."
  },
  faq: {
    1: {
      title: "Do I need RAS services?",
      answer:
        "We specialize in using technology to solve problems and bring convenience. So whether you have a:\n\n(1) Start-up corporate identity website \n(2) SME internal management system \n(3) E-commerce shopping site and customer management system \n\n Or any customized problem, we can evaluate and create the right system for you."
    },
    2: {
      title: "How is RAS different from others?",
      answer:
        "Before using any technology, we adhere to three main guidelines:\n\n(1) User perspective - to understand the needs\n(2) Customization without templates - to respect the uniqueness of each problem\n(3) Simplicity and intuitive design - to minimize the burden of use."
    },
    3: {
      title: "How can I contact RAS?",
      answer:
        "You can send us an Email (theras.tw@gmail.com). \nYou are welcome to describe your general needs in the letter, and any reference information can be attached to the email."
    }
  },
  footer: {
    sitemap: "Site map",
    contact: "Contact us"
  }
};
