import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

const meta = {
  description: {
    home: "RAS Studio。提供最可靠、可用與安全性的技術。",
    about:
      "RAS 三個字母除了代表可靠，可用與安全，也源自於工作室三人的英文名字開頭，分別是 Ryan, Andrew 與 Steven。",
    portfolio:
      "RAS Studio 作品集。我們做什麼，品牌形象網站、購物網站、APP、聊天機器人、客製化網站/系統。",
    faq: "我需要 RAS 的服務嗎? RAS 與其他公司的不同? 我該如何與 RAS 聯繫?"
  }
};

const router = new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        title: "RAS Studio",
        metaTags: [
          {
            name: "description",
            content: meta.description.home
          },
          {
            property: "og:description",
            content: meta.description.home
          }
        ]
      }
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
      meta: {
        title: "About Us | RAS Studio",
        metaTags: [
          {
            name: "description",
            content: meta.description.about
          },
          {
            property: "og:description",
            content: meta.description.about
          }
        ]
      }
    },
    {
      path: "/portfolio",
      name: "portfolio",
      component: () =>
        import(/* webpackChunkName: "portfolio" */ "./views/Portfolio.vue"),
      meta: {
        title: "Portfolio | RAS Studio",
        metaTags: [
          {
            name: "description",
            content: meta.description.portfolio
          },
          {
            property: "og:description",
            content: meta.description.portfolio
          }
        ]
      }
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import(/* webpackChunkName: "faq" */ "./views/Faq.vue"),
      meta: {
        title: "FAQ | RAS Studio",
        metaTags: [
          {
            name: "description",
            content: meta.description.faq
          },
          {
            property: "og:description",
            content: meta.description.faq
          }
        ]
      }
    }
  ]
});

export default router;
