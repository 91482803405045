
import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/homeSections/Footer.vue";
import Fragement from "./Fragement.vue";
import LanguageOptions from "@/components/LanguageOptions.vue";

@Component({
  components: {
    Fragement,
    LanguageOptions
  }
})
export default class SideMenu extends Footer {}
