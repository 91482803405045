
import { Component, Vue } from "vue-property-decorator";
import Fragement from "../Fragement.vue";

@Component({
  components: {
    Fragement
  }
})
export default class Footer extends Vue {
  public links = [
    {
      title: this.$t("link.home"),
      path: "/"
    },
    {
      title: this.$t("link.blog"),
      path: "https://blog.theras.tw",
      external: true
    },
    {
      title: this.$t("link.portfolio"),
      path: "/portfolio"
    },
    {
      title: this.$t("link.about"),
      path: "/about"
    },
    {
      title: this.$t("link.faq"),
      path: "/faq"
    }
  ];
  socialMedia = [
    {
      icon: ["fab", "instagram"],
      link: "",
      name: "instagram"
    },
    {
      icon: ["fab", "facebook-f"],
      link: "",
      name: "facebook"
    }
  ];

  get year(): string {
    return new Date().getFullYear().toString();
  }
}
