
import { TimelineLite, Elastic, Expo } from "gsap";
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import validator from "validator";

@Component
export default class ContactUs extends Vue {
  $refs!: {
    circle: HTMLElement;
    circlePulse: HTMLElement;
  };

  info = [
    {
      icon: "envelope",
      detail: "theras.tw@gmail.com",
      href: "mailto:theras.tw@gmail.com"
    }
    // {
    //   icon: "phone-alt",
    //   detail: "+886-979-101-924",
    //   href: "tel:+886-979-101-924"
    // }
  ];
  stepIndex = 0;
  err_msg = "";
  messageStep = [
    {
      title: this.$t("contact.messageStep.1.title"),
      type: "input",
      subTitle: this.$t("contact.messageStep.1.subTitle"),
      placeHolder: this.$t("contact.messageStep.1.placeHolder"),
      content: "",
      goBack: false,
      nextStep: true,
      autoFocus: false
    },
    {
      title: this.$t("contact.messageStep.2.title"),
      type: "input",
      subTitle: this.$t("contact.messageStep.2.subTitle"),
      placeHolder: this.$t("contact.messageStep.2.placeHolder"),
      content: "",
      goBack: true,
      nextStep: true,
      autoFocus: true
    },
    {
      title: this.$t("contact.messageStep.3.title"),
      type: "textarea",
      subTitle: this.$t("contact.messageStep.3.subTitle"),
      placeHolder: this.$t("contact.messageStep.3.placeHolder"),
      content: "",
      goBack: true,
      nextStep: true,
      autoFocus: true
    },
    {
      title: this.$t("contact.messageStep.4.title"),
      subTitle: this.$t("contact.messageStep.4.subTitle"),
      icon: ["far", "smile"],
      goBack: false,
      nextStep: false
    }
  ];

  private validateEmail() {
    return validator.isEmail(String(this.messageStep[0].content).toLowerCase());
  }

  private sendMail() {
    axios
      .post("https://api.stevenlin.tw/alert/warning", {
        title: this.messageStep[1].content,
        content: `From ${this.messageStep[0].content}\n\nContent: ${this.messageStep[2].content}`,
        contacts: ["sycLin", "ryanc", "wuder90622"]
      })
      .then(function(response) {
        // Do nothing
      })
      .catch(function(error) {
        alert("oops 系統發生錯誤，請撥打我們的電話或是直接 email 聯繫我們！");
      });
    return false;
  }

  nextStep() {
    const totalStep = this.messageStep.length;
    switch (this.stepIndex) {
      case 0:
        if (!this.validateEmail()) {
          this.err_msg = this.$tc("contact.error.email");
          return;
        }
        break;
      case 1:
        if (!this.messageStep[1].content) {
          this.err_msg = this.$tc("contact.error.subject");
          return;
        }
        if (
          this.messageStep[1].content &&
          this.messageStep[1].content.length > 30
        ) {
          this.err_msg = this.$tc("contact.error.subjectLimit");
          return;
        }
        break;
      case 2:
        if (!this.messageStep[2].content) {
          this.err_msg = this.$tc("contact.error.content");
          return;
        }
        if (
          this.messageStep[2].content &&
          this.messageStep[2].content.length > 300
        ) {
          this.err_msg = this.$tc("contact.error.contentLimit");
          return;
        }
        this.sendMail();
        break;
    }
    if (this.stepIndex < totalStep - 1) {
      this.stepIndex += 1;
      this.circleAnim();
    }
    this.err_msg = "";
  }

  private circleAnim() {
    const { circle, circlePulse } = this.$refs;
    const timeline = new TimelineLite({});
    timeline.to(
      circlePulse,
      0.8,
      {
        scale: 0.9,
        opacity: 1
      },
      "-=0.6"
    );
    timeline.to(circle, 1.2, {
      scale: 1,
      ease: Elastic.easeOut.config(2.5, 0.5)
    });
    timeline.to(
      circlePulse,
      1.1,
      {
        scale: 2,
        opacity: 0,
        ease: Expo.easeOut
      },
      "-=1.2"
    );
  }
}
