export default {
  slogan: {
    title: "提供最可靠、可用與安全性的技術。"
  },
  title: "選擇 RAS",
  whyUs: {
    1: {
      title: "公開透明",
      aspect: "報價",
      content:
        "我們會在討論需求後，給予您條列分明的報價單。RAS 估價過程中，我們分析您所需要的元件及其詳細規格。"
    },
    2: {
      title: "清楚明瞭",
      aspect: "時程",
      content:
        "透過有效的使用專案規劃管理工具，讓 RAS 可以預估設計、開發、測試、移交，四大里程碑的時間，並準時交付。"
    },
    3: {
      title: "及時有效",
      aspect: "溝通",
      content:
        "我們使用單一的聯絡窗口，讓溝通更直接、更輕鬆。在上班日，您一定會在 24 小時內收到 RAS 的回覆。"
    },
    4: {
      title: "客製需求",
      aspect: "開發",
      content:
        "透過 RAS 量身打造專屬風格的網站。在諮詢和討論過程中，讓 RAS 更深入了解您的需求並解答您的疑惑。"
    }
  },
  link: {
    home: "首頁",
    blog: "部落格",
    portfolio: "專案列表",
    about: "關於我們",
    faq: "常見問題"
  },
  portfolio: {
    notActive: "已下架",
    lebecca: "利百加生物科技 - 購物網站",
    molong: "保佳利健康 - 購物網站",
    molongLineBot: "創新創業激勵計劃 - 水龍頭濾芯 LINE Bot",
    musicAILab: "中研院音樂與人工智慧實驗室",
    ntuElection: "台大校長學生投票網"
  },
  contact: {
    title: "聯繫我們",
    subTitle:
      "若有合作的意願，歡迎隨時聯繫我們，收到您的信件後我們必會盡快的回覆的",
    messageStep: {
      1: {
        title: "您的信箱",
        subTitle: "請再三檢查是否有填正確，好讓我們能聯繫到您。",
        placeHolder: "填入您的信箱"
      },
      2: {
        title: "主旨",
        subTitle: "幫忙打上簡易主旨吧！好讓我們一目瞭然。",
        placeHolder: "填入主旨"
      },
      3: {
        title: "內容",
        subTitle:
          "告訴我們您的需求與期待，好讓我們在回信給您前已經有初步的規劃。",
        placeHolder: "填入內容"
      },
      4: {
        title: "謝謝您成功跨出了第一步，我們將會很快聯繫你 :)",
        subTitle: "Nice to meet you, we will contact you soon."
      }
    },
    error: {
      email: "請檢查 email 是否正確！",
      subject: "請輸入主旨！",
      subjectLimit: "主旨請在 30 字內！",
      content: "請輸入內容！",
      contentLimit: "內容請在 300 字內！"
    }
  },
  about: {
    1: "RAS 三個字母除了代表可靠 (Reliability)，可用 (Availability) 與安全 (Security)，也源自於工作室三人的英文名字開頭，分別是 Ryan, Andrew 與 Steven。",
    2: "我們三人皆畢業於台大資工所，自學生時期就已經合作過大大小小的專案，比如台大校長學生投票系統，中研院 AI 實驗室網站等。",
    3: "為何有這間工作室？是某個午後我們三個坐在咖啡廳聊天、回憶起學生時期合作的酸甜苦辣，進而思考為何不利用下班之餘，再次一起做專案。RAS Studio 目前就是會先以接案為主，也會慢慢發想我們認為有趣的題目。最後，一路走來我們的共同默契就是把事情做到最好，如 RAS 所代表，我們提供可靠、可用兼具安全的服務，也致力於打造好用並且美觀的介面。"
  },
  faq: {
    1: {
      title: "我需要 RAS 的服務嗎？",
      answer:
        "我們專注於用技術解決問題、帶來便利。所以無論你是\n(1) 新創公司形象網站\n(2) 中小企業製作內部管理系統\n(3) 電商購物網站與客戶管理系統\n或是任何客製化的問題，我們都可以為您評估並製作合適的系統。"
    },
    2: {
      title: "RAS 與其他公司的不同？",
      answer:
        "在使用任何技術之前，我們秉持三大準則：\n(1) 以使用者角度出發 - 了解需求\n(2) 客製化不套模板 - 尊重每一個問題的獨特性\n(3) 簡潔直覺的設計 - 最小化使用上的負擔。"
    },
    3: {
      title: "我該如何與 RAS 聯繫？",
      answer:
        "您可以寄 Email 給我們 (theras.tw@gmail.com)。\n歡迎在信件中描述您的大致需求，有任何參考資料也可以附加在 Email 中。"
    }
  },
  footer: {
    sitemap: "網站地圖",
    contact: "聯繫我們"
  }
};
