export default {
  whyUs: {
    title: "Choose RAS",
    1: {
      title: "Open and Transparent",
      aspect: "Pricing",
      content:
        "During the RAS estimation process, we analyze the requirements and detailed specifications of the components you need."
    },
    2: {
      title: "Clearly and Understandable",
      aspect: "Schedule",
      content:
        "Through the effective use of project planning management tools, we can estimate the time for the four major milestones of design, development, testing, and handover, and deliver on time."
    },
    3: {
      title: "Timely and Effective",
      aspect: "Communication",
      content:
        "We use a single contact window to make communication more direct and easy. On business days, you'll be sure to hear back from RAS within 24 hours."
    },
    4: {
      title: "Customization",
      aspect: "Development",
      content:
        "Create your own customized image website through RAS. During the consultation and discussion process, RAS will be able to better understand your needs and answer your questions."
    }
  }
};
