
import { Component, Vue, Prop } from "vue-property-decorator";
import { TweenMax, TimelineMax, Expo } from "gsap";
import Footer from "@/components/homeSections/Footer.vue";
import SideMenu from "@/components/SideMenu.vue";
import LanguageOptions from "@/components/LanguageOptions.vue";

@Component({
  components: {
    Footer,
    SideMenu,
    LanguageOptions
  }
})
export default class LayoutWithNavAndFooter extends Vue {
  @Prop({ type: Boolean, default: true }) renderFooter: any;
  isWindowTop: boolean = true;
  isMenuOpen: boolean = false;
  private animDuration: number = 1.8;
  private animDelay: number = 0.2;
  private menuAnim: any;
  private navBarAnim: any;
  private languageButtonAnim: any;
  private documentRoot = document.querySelector<HTMLElement>("html");

  $refs!: {
    bg: HTMLElement;
    studio: HTMLElement;
    navbar: HTMLElement;
    menu: HTMLElement;
    menuButton: HTMLElement;
    languageButtons: HTMLElement;
    wrap: HTMLElement;
  };

  mounted() {
    this.init();
    window.addEventListener("scroll", this.handleScroll);
    (this.documentRoot as HTMLElement).classList.remove("is-clipped");
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  init() {
    const {
      studio,
      navbar,
      menu,
      menuButton,
      languageButtons,
      wrap,
      bg
    } = this.$refs;
    TweenMax.to([studio], this.animDuration, {
      color: "#3b3b3b",
      delay: this.animDelay * 5
    });
    TweenMax.to([languageButtons, menuButton], this.animDuration, {
      opacity: 1,
      delay: this.animDelay * 2
    });
    this.menuAnim = new TimelineMax({ paused: true })
      .to([wrap], 0.2, {
        opacity: 0.2
      })
      .to(menu, 0.2, {
        right: 0
      });
    this.navBarAnim = new TimelineMax({ paused: true })
      .to(navbar, 0.8, {
        padding: 0,
        ease: Expo.easeIn
      })
      .to(menuButton, 0.5, {
        backgroundColor: "#3625d3"
      });
    this.languageButtonAnim = new TimelineMax({
      paused: true
    }).to(languageButtons, 0.5, { opacity: 0, color: "white" });

    const bgAnim = new TimelineMax({ paused: true })
      .to(wrap, 0, {
        opacity: 0
      })
      .to(bg, 0, {
        display: "block",
        xPercent: -110,
        skewX: 15,
        yPercent: -10
      })
      .to(bg, 0.4, {
        display: "block",
        xPercent: -30,
        ease: Expo.easeIn
      })
      .to(bg, 0.6, {
        xPercent: 50,
        ease: Expo.easeIn,
        skewX: 0,
        yPercent: 0
      })
      .to(bg, 0.4, {
        xPercent: 100,
        ease: Expo.easeOut
      })
      .to(wrap, 0.3, {
        opacity: 1,
        ease: Expo.easeIn
      });
    bgAnim.play();
  }

  private handleScroll(e: Event) {
    const { studio } = this.$refs;
    if (window.scrollY > 10 && this.isWindowTop) {
      this.isWindowTop = false;
      TweenMax.to([studio], 0.5, {
        opacity: 0
      });
      this.languageButtonAnim.play();
      this.navBarAnim.play();
    } else if (window.scrollY <= 10 && !this.isWindowTop) {
      this.isWindowTop = true;
      TweenMax.to([studio], 0.5, {
        opacity: 1
      });
      this.languageButtonAnim.reversed(true);
      this.navBarAnim.reversed(true);
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.isMenuOpen
      ? (this.documentRoot as HTMLElement).classList.add("is-clipped")
      : (this.documentRoot as HTMLElement).classList.remove("is-clipped");
    this.isMenuOpen ? this.menuAnim.play() : this.menuAnim.reversed(true);
  }
}
