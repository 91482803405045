import Vue from "vue";
import VueI18n from "vue-i18n";
import langs, { Lang } from "./config/langs";

Vue.use(VueI18n);

let locale = Lang.TW;

const userLang = localStorage.getItem("lang") || navigator.language;
if (typeof userLang !== undefined) {
  switch (userLang) {
    case Lang.TW: {
      locale = Lang.TW;
      break;
    }
    case Lang.JA: {
      locale = Lang.JA;
      break;
    }
    default: {
      locale = Lang.EN;
      break;
    }
  }
}

export const setUserLang = (value: Lang) => {
  localStorage.setItem("lang", value);
};

const i18n = new VueI18n({
  locale,
  messages: langs
});

export default i18n;
