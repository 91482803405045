
import { Component, Vue } from "vue-property-decorator";
import Landing from "@/components/homeSections/Landing.vue";
import Services from "@/components/homeSections/Services.vue";
import WhyUs from "@/components/homeSections/WhyUs.vue";
import ContactUs from "@/components/homeSections/ContactUs.vue";
import Footer from "@/components/homeSections/Footer.vue";
import LayoutWithNavAndFooter from "@/layout/LayoutWithNavAndFooter.vue";

@Component({
  components: {
    Landing,
    Services,
    WhyUs,
    ContactUs,
    Footer,
    LayoutWithNavAndFooter
  }
})
export default class Home extends Vue {}
